<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(f, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="f"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid">
            <!-- author -->
            <div class="ctl-item-param">
              {{ f.user && f.user.email }}
            </div>
            <!-- create at -->
            <div class="ctl-item-param">
              {{ getCorrectDate(f.created_at).slice(0, 19) }}
            </div>
            <!-- score -->
            <div class="ctl-item-param">
              {{ f.score }}
            </div>
            <!-- like/dislike -->
            <div class="ctl-item-param">
              <div
                v-for="(value, key) in getSelections(f.selection)"
                :key="key"
              >
                <div v-if="value">
                  {{ key }}:
                  <span
                    :style="{ color: value === 'like' ? 'green' : 'red' }"
                    >{{ value }}</span
                  >
                </div>
              </div>
            </div>
            <!-- like/dislike -->
            <div class="ctl-item-param">
              {{ f.comment }}
            </div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilCatalogFilters from "@/components/catalog/Filters";
import "@/assets/css/catalog.css";
import catalogMixin from "@/mixins/catalogMixin";

export default {
  name: "fontsCatalog",
  mixins: [catalogMixin],
  props: ["tab"],
  components: {
    PilCatalogFilters
  },

  data() {
    return {
      // ? filter select items list
      filters: {},
      sortParams: { created_at: "desc" },
      // ? titles
      titlesList: [
        { name: "author" },
        { name: "data" },
        { name: "score" },
        { name: "like/dislike" },
        { name: "comment" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "catalog/feedbacks"
    })
  },
  methods: {
    getSelections(selections) {
      try {
        return JSON.parse(selections);
      } catch (error) {
        return {};
      }
    }
  }
};
</script>

<style scoped>
.preview {
  max-height: 22px;
}
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(150px, 150fr)
    minmax(120px, 120fr)
    minmax(70px, 70fr)
    minmax(400px, 400fr)
    minmax(400px, 400fr);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.ctl-item {
  height: 100px;
}
</style>
